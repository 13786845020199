import config from '../../config';
import IMAGEM from '../imagem';
import './style.css';

const Participantes = ({ nome, endereco, telefone, foto, logo, site }) => {
    return <div className="custom-card cardestab card justify-content-center" >
              <div className='d-block mt-2'>
                <div className="custom-card-header">
                  <IMAGEM 
                      participantes={true}
                      src={`${config.BASEIMG}${foto}`} 
                      width={100} 
                      className="card-img-top" 
                      alt={nome} />
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">{nome}</h5>
                <p className="card-text">{endereco}</p>
                <p className="card-text">{telefone}</p>
                <a href={site} target='_blank' className="btn btn-primary">Acesse aqui</a>
              </div>
            </div>
}

// const Participantes = ({ nome, endereco, telefone, foto, logo }) => {
//   return (
//     <div className="participante">
//       <img src={`${config.BASEIMG}${foto}`} alt={nome} className="w-80 mb-2" />
//       <h4>{nome}</h4>
//       <p>{endereco}</p>
//       <p>{telefone}</p>
//       {//logo && <img src={logo} alt={`${nome} logo`} className="logo" />
//       }
//     </div>
//   );
// };

export default Participantes;
