import { useEffect, useRef, useState } from "react";
import EDITMODAL from '../../../../components/editmodal';
import api from "../../../../services/api";
import FLOATINPUT from '../../../../components/inputnumber';
import './style.css';
import { getHoje } from "../../../../components/formatos";
import { getnome } from "../../../../functions/gettabconfig";
import { FormataValor } from "../../../../functions/formatavalor";
import QRCODEWRITE from "../qrcode";
import config from "../../../../config";
import SELECT from "../../../../components/select";

function VENDASModal(props) {

  const MAX_VALOR_VENDA = 50000.00;

  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState('');

  const [idvenda, setIDVenda] = useState(0);
  const [idcampanha, setIDCampanha] = useState(0);
  const [dencampanha, setDenCampanha] = useState('');
  const [idestabelecimento, setIDEstabelecimento] = useState(0);
  const [nomeestabelecimento, setNomeEstabelecimento] = useState('');
  const [idusuario, setIDUsuario] = useState(0);
  const [datvenda, setDatVenda] = useState(getHoje());
  const [numerovenda, setNumerVenda] = useState('');
  const [valorvenda, setValorVenda] = useState(0.00);
  const [idvendedor, setIDVendedor] = useState(0);  

  const [lusuarios, setLUsuarios] = useState([]);
  const [inputnome, setInputNome] = useState('');
  const inputRef = useRef(null);

  const [ppqrcode, setPpqrcode] = useState(false);

  const [avendedores, setAVendedores] = useState([]);

  function Salva() {

    if(numerovenda.trim().length===0){
      setMensagem('Numero da venda invalido');
      return;
    }
    if(valorvenda <= 0){
      setMensagem('Valor da Venda invaldo');
      return;
    }   
    if(parseFloat( valorvenda ) > MAX_VALOR_VENDA){
      setMensagem('Valor da Venda acima do limite');
      return
    }  
    if(idusuario < 0){
      setMensagem('Defina o Destinatário');
      return;
    }
    setLoading(true);
    const dados = {
      "id_campanha": idcampanha,
      "id_estabelecimento": idestabelecimento,
      "id_usuario": idusuario,
      "dat_venda": datvenda,
      "numero_venda": numerovenda,
      "valor_venda": parseFloat(valorvenda.toString().replace(",", ".")),
      "id_vendedor": idvendedor
    }
    if (idvenda > 0) {
      api.patch(`/v1/parceiro/vendas/${idvenda}`, dados)
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            props.onRequestClose();
          } else {
            setLoading(false);
            setMensagem(response.response.data.erro)
          }
        })
    } else {
      api.post(`/v1/parceiro/vendas/`, dados)
        .then(response => {
          if (response.status === 201) {
            setLoading(false);
            props.onRequestClose();
          } else {
            setLoading(false);
            setMensagem(response.response.data.erro)
          }
        })
    }
  }

  function Deleta() {
    setMensagem('');
    setLoading(true);
    api.delete(`/v1/parceiro/vendas/${idvenda}`)
      .then(response => {
        if (response.status === 200) {
          setLoading(false);
          props.onRequestClose();
        } else {
          setLoading(false);
          setMensagem(response.response.data.erro)
        }
      })

  }

  const buscaUsuario = (event) => {
    const value = event.target.value;
    setInputNome(value);

    api.get(`${config.PARCEIRO_USUARIOS}?nome=${value}`)
      .then(response => {
        setLUsuarios(response.data);
      })
  };

  const setSelecionausuario = (u) => {
    setInputNome(u.nome);
    setIDUsuario(u.id_usuario);
    setLUsuarios([]);
  }

  function listaUsuarios() {
    return <div style={{
      position: 'absolute',
      top: 'calc(100% + 5px)',
      left: 0,
      width: '100%',
      backgroundColor: 'white',
      border: '0px solid #ccc',
      borderRadius: '4px',
      zIndex: 1,
    }}>
      {lusuarios.length > 0 ?
        <ul>
          {
            lusuarios.map((u, index) => {
              if (index < 6) {
                return <li key={index}
                  onClick={() => setSelecionausuario(u)}>
                  {u.nome},{u.whatsup}
                </li>
              }
            })
          }
        </ul>
        : null
      }
    </div>
  }

  function ativaqrcode(){
    if(numerovenda.trim().length===0){
      setMensagem('Numero da venda invalido');
      return;
    }
    if(valorvenda <= 0){
      setMensagem('Valor da Venda invaldo');
      return
    }
    if(parseFloat( valorvenda ) > MAX_VALOR_VENDA){
      setMensagem('Valor da Venda acima do limite');
      return
    }  
    if(idusuario > 0){
      setMensagem('Voce ja definiou para quem é esta venda');
      return
    }
    setPpqrcode(true)
  }

  function onCloseQRCode(){
    setPpqrcode(false);
    setAVendedores([]);
    props.onRequestClose();
  }

  useEffect(() => {
    
    if(props.dados){
      setIDVenda(props.dados.id_venda);
      setIDCampanha(props.dados.id_campanha);
      setDenCampanha(props.dados.den_campanha)
      setIDEstabelecimento(props.dados.id_estabelecimento);
      setNomeEstabelecimento(props.dados.nome_estabelecimento);
      setIDUsuario(props.dados.id_usuario);
      setDatVenda(props.dados.dat_venda);
      setNumerVenda(props.dados.numero_venda);
      setValorVenda(props.dados.valor_venda??0);
      setInputNome(props.dados.nomeusuarios)
      setIDVendedor(props.dados.id_vendedor ?? 0)    
      setMensagem('');
      if (inputRef.current) {
        inputRef.current.focus();
      }
      if(props.dados.id_estabelecimento){
      api.get(`${config.ESTABELECIMENTOS}/vendedores/${props.dados.id_estabelecimento}`)
      .then(response => {
        var lista = [{
                      "id_estabelecimento": 1,
                      "id_vendedor": 99,
                      "nome_vendedor": "Sem Vendedor"
                      }];
        lista.push(...response.data??[]) 
        setAVendedores(lista);
      })
    }
    }
  }, [props.dados])


  return <EDITMODAL
    programa={'REGISTRAR VENDA PARCEIRO'}
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    loading={loading}
    operacao={props.operacao}
    mensagem={mensagem}
    SalvaParametro={Salva}
    DeleteParametro={Deleta}
    dcreate={getHoje()}
    ocreate={getnome()}
    dupdate={getHoje()}
    oupdate={getnome()}
  >
    <h2>{nomeestabelecimento}</h2>
    <div className="row">
    <div className="col-md-2 col-2 d-md-block d-none">
        <label htmlFor="IDVenda" className="form-label">Venda</label>
        <input type="text"
          value={idvenda}
          disabled
          className="form-control" id="IDVenda" />
      </div>
      <div className="col-md-2 col-2 d-md-block d-none">
        <label htmlFor="IDCampanha" className="form-label">Campanha</label>
        <input type="text"
          value={idcampanha}
          onChange={(e) => setIDCampanha(e.target.value)}
          disabled
          className="form-control" id="IDCampanha" />
      </div>
      <div className="col-md-4 col-4 d-md-block d-none">
        <label htmlFor="selectCampanha" className="form-label">Campanha</label>
        <input type="text"
          value={dencampanha}
          disabled
          className="form-control" id="selectCampanha" />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputDVenda" className="form-label">Emissão</label>
        <input type="date"
          value={datvenda}
          onChange={(e) => setDatVenda(e.target.value)}
          className="form-control" id="inputDVenda" />
      </div>
      <div className="col-md-4 col-4 d-md-block d-none">
        <label htmlFor="selectCampanha" className="form-label">Código</label>
        <input type="text"
          value={idusuario}
          disabled
          className="form-control" id="inputEstado" />
      </div>
      <div className="col-md-8 nomeusuario" style={{ position: 'relative' }}>
        <label htmlFor="inputUsuario" className="form-label">Destinatário</label>
        <input type="text"
          value={inputnome}
          onChange={(e) => buscaUsuario(e)}
          className="form-control" id="inputDen" />
        {listaUsuarios()}
      </div>
      <div className="col-md-4">
        <label htmlFor="inputVendedor" className="form-label">Vendedor</label>
        <SELECT
         key={"inputVendedor"}
         name={"inputVendedor"}
         SalvaSelect={setIDVendedor}
         value={idvendedor}
         chave={"id_vendedor"}
         descricao={"nome_vendedor"}
         aoptions={avendedores}
         retornoe={false}
         />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputNumero" className="form-label">Pedido/Cupom/Nota</label>
        <input type="text"
          value={numerovenda}
          onChange={(e) => setNumerVenda(e.target.value)}
          className="form-control" id="inputNumero"
          ref={inputRef} />
      </div>
      <div className="col-md-4">
        <label htmlFor="inputValor" className="form-label">Valor</label>
        <FLOATINPUT
          id={'inputValor'}
          css={'NUMEROD2'}
          number={valorvenda}
          setNumber={setValorVenda}
          isAllowed={(values) => {
            const { floatValue } = values;            
            return floatValue <= MAX_VALOR_VENDA && floatValue >= 0 ;
          }}
        />
      </div>
      { idvenda === 0 ?
                      <div className="col-md-2">
                        <label htmlFor="inputQRCode" className="form-label">Gerar QRCODE</label>
                        <button type="button" className=" form-control btn btn-secondary"
                        onClick={()=>ativaqrcode()}
                        ><i className="fas fa-qrcode"></i> QRCODE</button>
                      </div>
                    : null
      }
    </div>
    <QRCODEWRITE
    trigger={ppqrcode}
    setTrigger={onCloseQRCode}
    json={
      {
          "idcampanha": idcampanha,
          "dencampanha": dencampanha,
          "idestabelecimento": idestabelecimento,
          "nomeestabelecimento": nomeestabelecimento,
          "numerovenda": numerovenda,
          "valorvenda": parseFloat(valorvenda.toString().replace(",", ".")),
          "id_vendedor": idvendedor
      }
    }
    />
  </EDITMODAL>
}

export default VENDASModal;