import AvisoPopup from '../../../components/avisos';
import NAVBAR from '../../../components/navbar';
import './style.css';

function HomeParceiro(){
    return<>
    <NAVBAR />
    <div className='body-parceiro'>
        <h1>Home Parceiro</h1>
    </div>
    <AvisoPopup />
    </>
}
export default HomeParceiro;