import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Contemplados from '../contemplados'

const CarrosselContemplados = ({ lcontemplado }) => {
  const responsive = {
    superLargeDesktop: {
      // screens larger than 1920px
      breakpoint: { max: 4000, min: 1920 },
      items: 4
    },
    desktop: {
      // screens larger than 1024px
      breakpoint: { max: 1920, min: 1024 },
      items: 3
    },
    tablet: {
      // screens larger than 464px
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      // screens smaller than 464px
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };

  return (
    <>
      {lcontemplado.length > 0 && (
        <>
          <h1>Contemplados</h1>
          <Carousel responsive={responsive} infinite={true} className="mt-5">
            {lcontemplado.map((d, index) => (
              <div key={`contem${index}`} className="p-2">
                <Contemplados
                 banner={d.banner}
                 nome={d.nome}
                 nome_sorteado={d.nome_sorteado}
                 nome_estabelecimento={d.nom_estabelecimento}
                 limages={d.images}
                />
              </div>
            ))}
          </Carousel>
        </>
      )}
    </>
  );
};

export default CarrosselContemplados;
