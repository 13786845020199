import config from '../../config';
import IMAGEM from '../imagem';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

const Contemplados = ({banner,nome,nome_sorteado, nome_estabelecimento, limages})  =>{
   // var llimages = [{ id_foto: 99, url_foto: banner }, ...limages];
   var llimages = [...limages];

    return <div className="custom-card cardestab card justify-content-center" >
    <div className='d-block mt-2'>
    <Carousel>
          {llimages.map((i,index) => (
            <Carousel.Item key={`cont${index}`}>
              <div className="custom-card-header">
                    <IMAGEM 
                        participantes={true}
                        src={`${config.BASEIMG}${i.url_foto}`} 
                        width={250} 
                        className="card-img-top" 
                        alt={i.url_foto} />
                  </div>
            </Carousel.Item>
          ))}
        </Carousel>
    </div>
    <div className="card-body">
      <h5 className="card-title">{nome_sorteado}, parabéns.</h5>
      <p>Parabéns a {nome_estabelecimento}, voce é pé-quente.</p>
    </div>
  </div>
}

export default Contemplados;