import './style.css';

function FOOTER(){
    const ano = new Date().getFullYear();    
    return <footer className="footer page-footer d-block border-top mt-5">
    <div className="container">
        <div className="row">
            <div className="col-12 text-center mt-4">
                <p className="copyright">
                    <i className="fa fa-copyright" aria-hidden="true"></i>
                    {ano} Copyright: <a href='https://www.opnet.com.br' target='_blank'>Opnet Informática</a> - Todos os direitos reservados. 
                </p>
            </div>
        </div>
    </div>
</footer>
    
}
export default FOOTER;