import './style.css';

const Conquistas = ({ Conquista, getnome }) => {
  return (
    <div className='mt-3'>
      <h1>Conquistas</h1>
      <div>
        <div className='d-grid'>
          <button onClick={Conquista} className="btn btn-large btn-light custom-button">
            {`Olá ${getnome}  >`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Conquistas;
