import { useState } from "react";
import config from "../../../../../config";
import api from "../../../../../services/api";

function EDITVENDEDORES({ id_estabelecimento, avendedores, setAVendedores }) {
    const [inputnome, setInputNome] = useState('');
    const [idvendedor, setIDVendedor] = useState(0);
    const [lusuarios, setLUsuarios] = useState([]);

    const buscaUsuario = (event) => {
        const value = event.target.value;
        setInputNome(value);

        api.get(`${config.PARCEIRO_USUARIOS}?nome=${value}`)
            .then(response => {
                setLUsuarios(response.data);
            })
    };

    function listaUsuarios() {
        return <div style={{
            position: 'absolute',
            top: 'calc(100% + 5px)',
            left: 0,
            width: '100%',
            backgroundColor: 'white',
            border: '0px solid #ccc',
            borderRadius: '4px',
            zIndex: 1,
        }}>
            {lusuarios.length > 0 ?
                <ul>
                    {
                        lusuarios.map((u, index) => {
                            if (index < 6) {
                                return <li key={index}
                                    onClick={() => setSelecionausuario(u)}>
                                    {u.nome},{u.whatsup}
                                </li>
                            }
                        })
                    }
                </ul>
                : null
            }
        </div>
    }
    const setSelecionausuario = (u) => {
        setInputNome(u.nome);
        setIDVendedor(u.id_usuario);
        setLUsuarios([]);
    }
    const adicionarVendedor = (e,index) => {
        var lista = [...avendedores];
        const existe = lista.some(item => item.id_vendedor === idvendedor);
        if(!existe){
            lista.push({'id_estabelecimento': id_estabelecimento,
                        'id_vendedor': idvendedor,
                        'nome_vendedor': inputnome 
                        });
            setAVendedores(lista);
        }
        setInputNome('');
        setIDVendedor('0');
    }    
    const excluirVendedor = (e,index) => {
        var lista = [...avendedores];
        lista = lista.filter((_,i)=> i !==index);
        setAVendedores(lista);
    }

    return <>
        <div className="row">
            <div className="col-md-6 nomeusuario" style={{ position: 'relative' }}>
                <label htmlFor="inputUsuario" className="form-label">Destinatário</label>
                <input type="text"
                    value={inputnome}
                    onChange={(e) => buscaUsuario(e)}
                    className="form-control" id="inputDen" />
                {listaUsuarios()}
            </div>
            <div className="col-md-2">
                <button type="button" className="btn btn-primary"
                onClick={(e)=>adicionarVendedor(e)}>Adicionar</button>
            </div>
        </div>
        <div className="table-container">
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>                        
                        <th scope="col" className="d-none d-md-table-cell hidden-sm">Vendedor</th>
                        <th scope="col">Nome</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        avendedores.map((e, index) => {
                            return <tr key={`vnd${index}`}>
                                <th scope="row">{e.id_vendedor}</th>
                                <td className="d-none d-md-table-cell hidden-sm">{e.nome_vendedor}</td>
                                <td><button className='btn btn-danger'
                                            type="button"
                                            onClick={() => excluirVendedor(e,index)}>
                                            <i className="fas fa-trash"></i></button></td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
}

export default EDITVENDEDORES;