import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Logo from '../../assets/actus-logo-48132145_1.png';
import Fundo from '../../assets/fundo-login.png';
import SaltPassword from '../../services/md5';
import Footer from '../../components/footer';
import { useEffect } from 'react';
import apibasic from '../../services/apibasic';
import LinkLogin from '../../components/linklogin'
import './style.css';

function RemocaoUsuario(){

    const [senha, setSenha] = useState('');
    const [senha2, setSenha2] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [loading, setLoading] = useState(false);
    const [senhaiguais, setSenhasIguais] = useState(false);
    const [msg, setMsg] = useState('');
    const [searchParams] = useSearchParams();
	var code = searchParams.get('code') ?? '';
    
    function redefineSenha(e){
       e.preventDefault();

       if(senha!==senha2){
        alert('Senha invalida!')
        return false;
       }
       if(code.length<150){
        alert('Invalido formato!')
        return false;
       }
              
       setLoading(true);

       apibasic.post('/v1/usuarios/remocaousuario',{
           code: code,
           senha: SaltPassword(senha),
           senha2: SaltPassword(senha2)
       }).then(response => {
           // verificar se houve sucesso.
           if(response.status===202){
            setLoading(false);
            setSucesso('S'); 
           }
       }) 
       .catch(err => {
           setLoading(false);
           setSucesso('');           
       })
    }
    function setNovaSenha2(s){
        setSenha2(s);
       
        if(senha===''){
            setSenhasIguais(false);
        } else {
           setSenhasIguais(senha===s);           
        }        
    }

    useEffect(()=>{
        // Validar CODE
        if(code.length===150){
            setSucesso('R');
        }
    },[])

    function formRemoveUsuario(){
        return <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
            <form className="form-login mt-5">
                <img className="mb-5" src={Logo} alt="" />
                <h3 className="mb-4">Remoção de usuario</h3>
                <div className="form-floating mb-3">
                    <input type="password" onChange={(e) => setSenha(e.target.value)} className="form-control" id="floatinginput" placeholder="Senha" />
                    <label htmlFor="floatinginput">Senha</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="password" onChange={(e) => setNovaSenha2(e.target.value)} className="form-control" id="floatinginput" placeholder="Senha" />
                    <label htmlFor="floatinginput">Senha confirmação</label>
                </div>
                <button className="w-100 btn btn-lg btn-danger" disabled={!senhaiguais}
                    onClick={redefineSenha}>
                    {loading ? <div>
                        <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                        <span className="ms-2">Processando...</span>
                    </div>
                        :
                        <span className="ms-2">Excluir definitivamente</span>}
                </button>
                {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">{msg}</div> : null}
                <LinkLogin />

            </form>
        </div>
    }

    function formInvalido(){
        return <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
            <form className="form-login mt-5">
            <img className="mb-5" src={Logo} alt="" />
                <div className="alert alert-danger" role="alert">
                    <h4 className="alert-heading">Desculpe!</h4>
                    <p>Desculpe mas algo de errado aconteceu ou seu link expirou, por favor tente novamente ou entre em contado.</p>
                    <hr></hr>
                    <p className="mb-0">Seu contato é muito importante para nós, envie um e-mail para contato@opnet.com.br ou envie uma mensagem para WhatsApp 19-35822048.</p>
                </div>
                <LinkLogin />                
            </form>
        </div>
    }

    function formSucess(){
        return <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
            <form className="form-login mt-5">
                <img className="mb-5" src={Logo} alt="" />
                <div className="alert alert-info" role="alert">
                    <h4 className="alert-heading">Parabéns</h4>
                    <p>Sua conta foi totalmente removida, click no link abaixo e faça login.</p>
                    <hr></hr>
                    <p className="mb-0">Qualquer dúvida, envie um e-mail para contato@opnet.com.br ou envie uma mensagem para WhatsApp 19-35822048.</p>
                </div>
                <LinkLogin />                
            </form>
        </div>
    }

    return <div className="row">
        {
         sucesso==='R' ? formRemoveUsuario() : 
         sucesso==='S' ? formSucess()        : formInvalido()
        }
        <div className="col-sm-6 px-0 d-none d-sm-block">
            <img className="background-login" src={Fundo} alt="Opnet Mais" />
        </div>
        <Footer />
    </div>
}

export default RemocaoUsuario;