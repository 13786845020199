import { useEffect, useState } from "react";
import NAVBAR from "../../../components/navbar";
import api from "../../../services/api";
import AdminCampanhaModal from './modal';
import './style.css';
import { formatDate, getHoje } from "../../../components/formatos";
import config from '../../../config';
import FOOTER from "../../../components/footer";

function AdminCampanha(){
    const [lcampanha, setLCampanha] = useState([]);

    // Modal
    const [operacao, setOperacao] = useState('')
    const [dados, setDados] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    function closeAdminCampanhaModal(){
        setIsOpen(false);
    }

    useEffect(()=>{
        api.get(config.ADM_CAMPANHA)
        .then( response => {
            setLCampanha(response.data);
        });
    },[isOpen])

    useEffect(()=>{
        api.get(config.ADM_CAMPANHA)
        .then( response => {
            setLCampanha(response.data);
        });
    },[])

    function novaCampanha(){
        setDados({           
           'id_campanha':0,
           'den_campanha':'',
           'validade_ini':getHoje(),
            'validade_fim':getHoje(),
            'banner':''});
        setIsOpen(true);
    }

    function editarCampanha(linha){
            api.get(`${config.ADM_CAMPANHA}/${linha.id_campanha}`)
            .then(response => {
                 setOperacao('UPDATE')
                 setDados(response.data[0]);
                 setIsOpen(true);
            }).catch(err => {
                console.log(err)
                //document.location = `/empresaamiga/erro?m=No momento não conseguimos alcançar o servidor`
        })  
        }


    return <div>
        <NAVBAR />
        <AdminCampanhaModal isOpen={isOpen}
                  onRequestClose={closeAdminCampanhaModal}                 
                  operacao={operacao}
                  dados={dados}
        /> 
        <div className="mt-page">
            <h1>Campanhas</h1>
            <div className="table-container">
                <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                    <th scope="col"># <button type="button"
                    onClick={(e)=>novaCampanha()}
                    >Nova</button></th>
                    <th scope="col" className="d-none d-md-table-cell hidden-sm">Descrição</th> 
                    <th scope="col">Inicio</th>            
                    <th scope="col">Final</th>
                    <th scope="col">operação</th>
                    </tr>
                </thead>        
                <tbody>
                    {
                        lcampanha.map((e,index) =>{
                            return <tr key={`vnd${index}`}>
                                <th scope="row">{e.id_campanha}</th>
                                <td className="d-none d-md-table-cell hidden-sm">{e.den_campanha}</td>
                                <td>{formatDate(e.validade_ini)}</td>
                                <td>{formatDate(e.validade_fim)}</td>
                                <td><button className='btn btn-warnning' 
                                    type="button"
                                    onClick={()=>editarCampanha(e)}>Editar</button></td>
                            </tr>           
                        })   
                    }            
                </tbody>
                </table>
            </div>
        </div>
        <FOOTER />
    </div>
}

export default AdminCampanha;