import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/private-route';
import Principal from './pages/home';
import PageError from './pages/pageerror';
import Perfil from './pages/perfil';
import Login from './pages/login';
import SolicitaRedefinirSenha from './pages/solicitanovasenha';
import RedefinirSenha from './pages/redefinirsenha';
import RemocaoUsuario from './pages/remocaousuario';
import Cadastro from './pages/cadastro';
import MeuHome from './pages/meu/home';
import MeuExtrato from './pages/meu/extrato';
import MeuCupom from './pages/meu/cupom';
import MeuDesconto from './pages/meu/desconto';
import MeuConquista from './pages/meu/conquista';
import PrivateParceiro from './pages/parceiro/private-parceiro';
import LoginParceiro from './pages/parceiro/login';
import HomeParceiro from './pages/parceiro/home';
import VendaParceiro from './pages/parceiro/vendas';
import CupomParceiro from './pages/parceiro/cupom';
import DescontoParceiro from './pages/parceiro/desconto';
import PrivateAdmin from './pages/admin/private-parceiro';
import LoginAdmin from './pages/admin/login';
import HomeAdmin from './pages/admin/home';
import AdminCampanha from './pages/admin/campanha';
import AdminEstabelecimento from './pages/admin/estabelecimento';
import config from './config';
import AdminContemplado from './pages/admin/contemplado';

import { genarateToken, messaging } from './fcm/firebase';
import { useEffect } from 'react';
import { onMessage } from 'firebase/messaging';
import { getidusuario } from './functions/gettabconfig';
import { salvaPushNotification } from './fcm/salvePushNotification';
import api from './services/api';
import NotificaocaPage from './pages/notificacao';
import AdminNotificacao from './pages/admin/notificacao';
import AdminAviso from './pages/admin/avisos';


function Rotas(){
    const handleTokenGeneration = async () => {
        const token = await genarateToken();
        try {
            await salvaPushNotification(token);       
        } catch (e) {
            console.log(e);
        }
        
      };
    
    useEffect(()=>{
        if(getidusuario() !== '-1'){
            handleTokenGeneration();
            onMessage(messaging, (payload) => {
                const dados = 
                    {
                        type: "N",
                        title: payload.notification?.title,
                        body: payload.notification?.body,
                        image: payload.notification?.image
                    }
                api.post('/v1/usuarios/notificacao', dados);
                
                
                // Solicitar permissão do usuário
                if (Notification.permission === 'default') {
                    Notification.requestPermission().then(permission => {
                    if (permission === 'granted') {
                        console.log('Permissão para notificações concedida.');
                    }
                    });
                }

                // Verifique se as notificações estão permitidas
                if (Notification.permission === 'granted') {
                    const notificationTitle = payload.notification?.title || 'Notificação Padrão';
                    const notificationOptions = {
                        body: payload.notification?.body || 'Mensagem padrão',
                        icon: payload.notification?.image || ''
                    };
                    
                    // Exibir a notificação
                    new Notification(notificationTitle, notificationOptions);
                }
            });
        }    
      },[]);

    return <>
           <BrowserRouter basename={config.BASENAME}>
            <Routes>
                {/* site Principal cliente */}
                <Route exact path='/' element={<PrivateRoute><Principal /></PrivateRoute>}/>
                <Route exact path='/login' element={<Login />} /> 
                <Route exact path='/cadastro' element={<Cadastro />} />     
                <Route exact path='/erro401' element={<PageError />} />
                <Route exact path='/perfil' element={<PrivateRoute><Perfil /></PrivateRoute>} />
                <Route exact path='/solicitaredefinir' element={<SolicitaRedefinirSenha />} />
                <Route exact path='/redefinirsenha' element={<RedefinirSenha />} />
                <Route exact path='/remocaousuario' element={<PrivateRoute><RemocaoUsuario /></PrivateRoute>} />
                <Route exact path='/notificacao' element={<PrivateRoute><NotificaocaPage /></PrivateRoute>} />
                
                {/* Meu */}
                <Route exact path='/meu/home' element={<PrivateRoute><MeuHome /></PrivateRoute>} />
                <Route exact path='/meu/extrato' element={<PrivateRoute><MeuExtrato /></PrivateRoute>} />
                <Route exact path='/meu/desconto' element={<PrivateRoute><MeuDesconto /></PrivateRoute>} />
                <Route exact path='/meu/cupom' element={<PrivateRoute><MeuCupom /></PrivateRoute>} />
                <Route exact path='/meu/conquista' element={<PrivateRoute><MeuConquista /></PrivateRoute>} />                
                

                {/* subsite Parceiro */}
                <Route exact path='/parceiro/login' element={<LoginParceiro />} />
                <Route exact path='/parceiro' element={<LoginParceiro />} />
                <Route exact path='/parceiro/home'  element={<PrivateParceiro><HomeParceiro /></PrivateParceiro>} />
                <Route exact path='/parceiro/vender'  element={<PrivateParceiro><VendaParceiro /></PrivateParceiro>} />
                <Route exact path='/parceiro/cupom'  element={<PrivateParceiro><CupomParceiro /></PrivateParceiro>} />
                <Route exact path='/parceiro/desconto'  element={<PrivateParceiro><DescontoParceiro /></PrivateParceiro>} />
                <Route exact path='/parceiro/outrooo'  element={<PrivateParceiro><VendaParceiro /></PrivateParceiro>} />
                
                {/* subsite Adm */}
                <Route exact path='/admin/login' element={<LoginAdmin />} />
                <Route exact path='/admin' element={<LoginAdmin />} />
                <Route exact path='/admin/home'  element={<PrivateAdmin><HomeAdmin /></PrivateAdmin>} />
                <Route exact path='/admin/vender'  element={<PrivateAdmin><VendaParceiro /></PrivateAdmin>} />                
                <Route exact path='/admin/campanha'  element={<PrivateAdmin><AdminCampanha /></PrivateAdmin>} />
                <Route exact path='/admin/estabelecimento'  element={<PrivateAdmin><AdminEstabelecimento /></PrivateAdmin>} />
                <Route exact path='/admin/contemplado'  element={<PrivateAdmin><AdminContemplado /></PrivateAdmin>} />
                <Route exact path='/admin/desconto'  element={<PrivateAdmin><DescontoParceiro /></PrivateAdmin>} />
                <Route exact path='/parceiro/outrooo'  element={<PrivateAdmin><VendaParceiro /></PrivateAdmin>} />               
                <Route exact path='/admin/notificacao'  element={<PrivateAdmin><AdminNotificacao /></PrivateAdmin>} />
                <Route exact path='/admin/aviso'  element={<PrivateAdmin><AdminAviso /></PrivateAdmin>} />

            </Routes>
           </BrowserRouter>
    </>
}
export default Rotas;