import Campanha from "../campanha";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from "../../config";
import { formatDate } from "../formatos";

const CarrosselCampanhas = ({ lcompanha }) => {
    return (
      <div className='mt-3'>
        <h1>Campanhas</h1>
        <Carousel>
          {lcompanha.map(c => (
            <Carousel.Item key={`camp${c.id_campanha}`}>
              <div className='text-center'>
                <Campanha
                  descricao={c.den_campanha}
                  banner={`${config.BASEIMG}${c.banner}`}
                  inicio={formatDate(c.validade_ini)}
                  fim={formatDate(c.validade_fim)}
                  link={'https://mundiato.com.br/srpq/promo-actus/#promo'}
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  };
  
  export default CarrosselCampanhas;