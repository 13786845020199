import { useEffect, useState } from 'react';
import FLOATINPUT from '../../../../../components/inputnumber';
import './style.css';

function LISTAESTABELECIMENTOS( props ) {
    const [lfiltrada, setLFiltrada] = useState([]);
    const [searchestab, setSearchEstab] = useState('');
    const [pfator_cashback, setPFatorCashBack] = useState(0.0);
    const [pfator_sorteio, setPFatorSorteio] = useState(0.0);
    const [lista, setLista] = useState([])

    function setFatorCashBack(v, index) {
        const l = [...props.lestabelecimento];
        l[index].fator_cashback = v;
        props.setLEstabelecimento(l);
      }
      function setFatorSorteio(v, index) {
        const l = [...props.lestabelecimento];
        l[index].fator_sorteio = v;
        props.setLEstabelecimento(l);
      }
      function setParticipa(v, index) {
        const l = [...props.lestabelecimento];
        if (l[index].id_campanha === 0) {
          l[index].id_campanha = props.idcampanha;
        } else {
          l[index].id_campanha = 0;
        }
        props.setLEstabelecimento(l);
      }
    
      function searchEstabelecimento(v) {
        setSearchEstab(v);
        setLista(props.lestabelecimento.filter(e => e.nome.toLowerCase().includes(v.toLowerCase())));
      }
    
      function marcarTodos() {
        const l = [...props.lestabelecimento];
        l.forEach(e => {
          e.id_campanha = props.idcampanha;
          e.fator_cashback = pfator_cashback;
          e.fator_sorteio = pfator_sorteio;
        });
        props.setLEstabelecimento(l);
      }

      useEffect(()=>{
        setLista(props.lestabelecimento);
      },[props.lestabelecimento]);
      
      if (props.lestabelecimento.length > 0) {
      return <div className="container mt-2 custom-container">
        <table className="table table-striped table-bordered tabela-estabelecimento mt-3">
          <thead>
            <tr>
              <th scope="col" className="d-none d-md-table-cell hidden-sm">Nome<br />
              <div className="input-group rounded">
                <input type="search"
                  onChange={(e) => searchEstabelecimento(e.target.value)}
                  className="form-control"
                  id="floatinginputemail"
                  placeholder="Estabelecimento" />
                <button className="btn btn-outline-success"
                  type="button"><i className="fas fa-search" /></button>
              </div></th>
              <th scope="col">Fator Cash
              <FLOATINPUT
                id={`inputcashp`}
                css={'NUMEROD4'}
                decimalScale={4}
                number={pfator_cashback}
                setNumber={setPFatorCashBack}
              /></th>
              <th scope="col">Fator Sorteio
              <FLOATINPUT
                id={`inputsorteiop`}
                css={'NUMEROD4'}
                decimalScale={4}
                number={pfator_sorteio}
                setNumber={setPFatorSorteio}
              /></th>
              <th scope="col">Marcar todos<br/>
              <button className="btn btn-outline-success"
                onClick={() => marcarTodos()}
                type="button"><i className="fas fa-check" />
              </button></th>
            </tr>
          </thead>
          <tbody>
            {
             lista.map((e, index) => {
                  return <tr key={`vnd${index}`}>
                    <td className="d-none d-md-table-cell hidden-sm">{e.nome}</td>
                    <td><FLOATINPUT
                      index={index}
                      id={`inputcash${index}`}
                      css={'NUMEROD4'}
                      decimalScale={4}
                      number={e.fator_cashback}
                      setNumber={setFatorCashBack}
                    />
                    </td>
                    <td><FLOATINPUT
                      index={index}
                      id={`inputsorteio${index}`}
                      css={'NUMEROD4'}
                      decimalScale={4}
                      number={e.fator_sorteio}
                      setNumber={setFatorSorteio}
                    /> </td>
                    <td><div className="form-check">
                      <input className="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        value={props.idcampanha}
                        checked={e.id_campanha > 0}
                        onChange={(e) => setParticipa(e, index)}
                      />
                      <label className="form-check-label" for="flexCheckChecked">
                        Participa
                      </label>
                    </div></td>
                  </tr>
              })
            }
          </tbody>
        </table>
      </div>
    } else {
      return <div><p>Não encontrado estabelecimentos para listar</p></div>
    }
  }
export default LISTAESTABELECIMENTOS;