import { useEffect } from "react";
import api from '../../../services/api';
import NAVBAR from "../../../components/navbar";
import { useState } from "react";
import { FormataValor } from "../../../functions/formatavalor"
import { getadmin, getestabelecimentos, getnome } from "../../../functions/gettabconfig";
import { formatDate, getHoje } from "../../../components/formatos";
import VENDASModal from './modal'
import SELECT from "../../../components/select";
import './style.css';
import IMAGEM from "../../../components/imagem";
import config from "../../../config";
import MESSAGE from "../../../components/message";
import FOOTER from "../../../components/footer";
import AvisoPopup from "../../../components/avisos";

function VendaParceiro() {
    const [lvenda, setLVendas] = useState([]);
    const [operacao, setOperacao] = useState('')
    const [idcampanha, setIDCampanha] = useState(-1);
    const [dencampanha, setDenCampanha] = useState('');
    const [idestabelecimento, setIDEstabelecimento] = useState(getestabelecimentos(0));
    const [denestabelecimento, setDenEstabelecimento] = useState('');
    const [lparceiros, setLParceiros] = useState([]);
    const [lcampanha, setLCampanha] = useState([]);
    const [search, setSearch] = useState('');
    const [foto, setFoto] = useState('');
    const [mensagem, setMensagem] = useState('');


    // Modal
    const [dados, setDados] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    function novaVenda() {
        setDados({
            'id_venda': 0,
            'id_campanha': idcampanha,
            'den_campanha': dencampanha,
            'id_estabelecimento': idestabelecimento,
            'nome_estabelecimento': denestabelecimento,
            'id_usuario': -1,
            'dat_venda': getHoje(),
            'valor_venda': 0.00,
            'numero_venda': '',
            'nomeusuarios': ''
        });
        setIsOpen(true);
    }

    function editarVenda(linha) {
        api.get(`${config.PARCEIRO_VENDAS}/${linha.id_estabelecimento}/${linha.id_venda}`)
            .then(response => {
                setOperacao('UPDATE')
                setDados(response.data[0]);
                setIsOpen(true);
            }).catch(err => {
                console.log(err)
                setMensagem(err);
            })
    }

    function closeVENDASModal() {
        setIsOpen(false);
        BuscarExtrato();
    }

    function BuscaCampanha() {
        api.get(config.CAMPANHAS)
            .then(response => {
                if (response.status === 200) {
                    setLCampanha(response.data);
                    setIDCampanha(response.data[0].id_campanha);
                    setDenCampanha(response.data[0].den_campanha);
                } else {
                    setMensagem('Não carregou as campanhas');
                }
            });
    }

    function BuscaEstabelecimento() {
        api.get(`${config.ADM_ESTABELECIMENTOS}/${idestabelecimento}`)
            .then(response => {
                if (response.status === 200) {
                    setDenEstabelecimento(response.data[0].nome);
                    setFoto(response.data[0].url_logo);
                    if (response.data[0].campanhas.length === 0) {
                        setMensagem('Estabebelecimento não participa da campanha');
                        console.log('1-Estabebelecimento não participa da campanha')
                        setIDEstabelecimento(getestabelecimentos(0));
                        setLCampanha([]);
                    } else {
                        //BuscaCampanha();
                        setLCampanha(response.data[0].campanhas);
                        BuscarExtrato();
                    }
                } else {
                    setMensagem('Estabebelecimento não participa da campanha');
                    setIDEstabelecimento(getestabelecimentos(0));
                    console.log('2-Estabebelecimento não participa da campanha');
                    setLCampanha([]);
                }
            });
    }

    function BuscarExtrato() {
        if (idestabelecimento > 0  && idcampanha > 0) {
            api.get(`${config.PARCEIRO_VENDAS}?id_campanha=${idcampanha}&id_estabelecimento=${idestabelecimento}`)
                .then(response => {
                    if (response.status === 200) {
                        setLVendas(response.data);
                    }
                });
        }
    }

    useEffect(() => {
        BuscarExtrato();
    }, [idcampanha]);

    useEffect(() => {
        api.get(config.ADM_ESTABELECIMENTOS)
            .then(response => {
                if (response.status === 200) {
                    setLParceiros(response.data);
                    setIDEstabelecimento(getestabelecimentos(0));
                    BuscaEstabelecimento();
                    BuscaCampanha();
                    BuscarExtrato();
                }
            })
    }, []);

    useEffect(() => {
        BuscaEstabelecimento();
    }, [idestabelecimento])


    function salvaCampanha(e) {
        const id= parseInt(e.target.value, 10);
        setIDCampanha(id);
        const lden = lcampanha.filter(f => f.id_campanha === id);
        lden.length > 0 && setDenCampanha(lden[0].den_campanha);        
    }

    function setNovoEstabelecimento(e) {

        const id= parseInt(e.target.value, 10);
        setIDEstabelecimento(id);
        const lden = lparceiros.filter(f => f.id_estabelecimento === id);
        lden.length > 0 && setDenEstabelecimento(lden[0].nome);   
    }

    function KeyDown(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            searchLancamento();
        }
    }

    async function searchLancamento() {
        try {
            const response = await api.get(`${config.PARCEIRO_VENDAS}?id_campanha=${idcampanha}&id_estabelecimento=${idestabelecimento}`);
            const listaall = response.data;

            const lista = listaall.filter(venda => {
                const matchNumeroVenda = venda.numero_venda.includes(search);
                const matchNomeUsuarios = venda.nomeusuarios.toLowerCase().includes(search.toLowerCase());
                return matchNumeroVenda || matchNomeUsuarios;  // Considera correspondência em um ou outro campo
            });

            setLVendas(lista);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
    }

    return <div>
        <NAVBAR />
        <VENDASModal isOpen={isOpen}
            onRequestClose={closeVENDASModal}
            operacao={operacao}
            dados={dados}
        />
        <div className="container-fluid mt-page">
            <MESSAGE
                msg={mensagem}
            />
            <div className="me-2">
                <div className="row">
                    <div className="col-md-2">
                        <IMAGEM src={foto} width={100} />
                    </div>
                    {getadmin() ?
                        <div className="col-md-6">
                            <label htmlFor="SELECTPARCEIRO" className="form-label">Parceiro</label>
                            <SELECT
                                key={'SELECTPARCEIRO'}
                                name={'SELECTPARCEIRO'}
                                SalvaSelect={setNovoEstabelecimento}
                                value={idestabelecimento}
                                chave={'id_estabelecimento'}
                                descricao={'nome'}
                                aoptions={lparceiros}
                                retornoe={true}
                            />
                        </div>
                        : <h2>Venda Parceiro: {denestabelecimento}</h2>
                    }
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="SELECTCAMPANHA" className="form-label">Campanha</label>
                        <SELECT
                            key={'0001'}
                            name={'SELECTCAMPANHA'}
                            SalvaSelect={salvaCampanha}
                            value={idcampanha}
                            chave={'id_campanha'}
                            descricao={'den_campanha'}
                            aoptions={lcampanha}
                            retornoe={true}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="floatinginputsearch" className="form-label">Pesquisa</label>
                        <div className="input-group rounded">
                            <input type="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={KeyDown}
                                autoFocus={true}
                                className="form-control"
                                id="floatinginputsearch"
                                placeholder="Pesquisa lançamentos" />
                            <button className="btn btn-large"
                                type="button"
                                onClick={() => searchLancamento()}>
                                <i className="fas fa-search" /></button>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="Acoes" className="form-label">Ação</label>
                        <div className="toolbar">
                            <button type="button" className="toolbar-button form-control"
                                onClick={() => novaVenda()}
                            ><i className="fas fa-plus"></i></button>
                            <button type="button" className="toolbar-refresh form-control"
                                onClick={() => BuscarExtrato()}
                            ><i className="fas fa-sync"></i></button>
                        </div>
                    </div>
                </div>
                <div className="table-container">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className="d-none d-md-table-cell hidden-sm">Destinatário</th>
                                <th scope="col">Data</th>
                                <th scope="col">Valor</th>
                                <th scope="col">operação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lvenda.map((e, index) => {
                                    return <tr key={`vnd${index}`}>
                                        <th scope="row">{e.numero_venda}</th>
                                        <td className="d-none d-md-table-cell hidden-sm">{e.nomeusuarios}</td>
                                        <td>{formatDate(e.dat_venda)}</td>
                                        <td align="right">{FormataValor(e.valor_venda, 'NUMEROD2')}</td>
                                        <td><button className='btn btn-danger'
                                            type="button"
                                            onClick={() => editarVenda(e)}>
                                            <i className="fas fa-edit"></i></button></td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <FOOTER />
        <AvisoPopup />
    </div>

}
export default VendaParceiro;