import { useEffect, useState } from "react";
import NAVBAR from "../../../components/navbar";
import Cupom from "../../../components/cupom";
import api from '../../../services/api';
import CustomProgressBar from "../../../components/progressbar";
import './style.css';
import { getnome } from "../../../functions/gettabconfig";
import config from '../../../config';
import CarrosselCupons from "../../../components/carrosselCupons";
import FOOTER from "../../../components/footer";

function MeuConquista() {

    const [cupons, SetCupons] = useState([])
    const [cuponsunicos, SetCuponsUnicos] = useState([])    
    const [saldo, SetSaldo] = useState(0)
    const [idCupom, SetIdCupom] = useState([])
    const [dtEmissao, SetDtEmissao] = useState([])
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        api.get(config.CUPONS)
            .then(response => {
                SetSaldo(response.data[0].saldo)
                SetCupons(response.data[0].cupons)
                setProgress((response.data[0].saldo / 1) * 100);
                const uniqueIdSorteio = [...new Set(response.data[0].cupons.map(cupom => cupom.id_sorteio))]
                SetCuponsUnicos(uniqueIdSorteio)
            });

    }, [])

    return <div className="container-fluid mt-page">
        <NAVBAR />
        <h1 className="mt-1 ms-2">Minhas conquistas</h1>
        <div className="col-lg-8 offset-lg-2">
        <div className="card-ola">
            <div className="card-content">
                <h4>Olá {getnome()}</h4>
                <h6 className="card-ola-title">veja suas conquistas</h6>
                <div className="card-ola-subtitle">
                <p className="cupons">Cupons: {cuponsunicos.length ?? 0}</p>
                <p className="saldo">Saldo atual: {saldo ?? 0} pts</p>
                </div>
            </div>
        </div>
        </div>
        <div className="col-lg-8 offset-lg-2">
            <div className="row mt-3 ms-3 me-3">
                <div className="mb-3">
                    <small className="row ms-1 mb-2">Progresso até o próximo cupom:</small>
                    <CustomProgressBar progress={progress}
                        label={Math.floor(progress).toString() + '%'} />
                </div>
                <div className="row mb-3">
                    <h1>Confira o numero dos cupons...</h1>
                    {
                        cuponsunicos.map((c,index)=>{
                            return <div kye={`ncupons${index}`} className="col-md-1">
                                <spam>{c}</spam>
                            </div>
                        })
                    }
                    
                </div>             
                <CarrosselCupons
                    lcupons={cupons}
                />
            </div>
        </div>
        <FOOTER />
    </div>
}

export default MeuConquista;