import './style.css';
import Logo from '../../assets/logo-negativo.png';
import Fundo from '../../assets/fundo-login.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apibasic from '../../services/apibasic';
import SaltPassword from '../../services/md5';
import LinkLogin from '../../components/linklogin';
import DLGMESSAGE from '../../components/dlgmensagem';
import config from '../../config';

function Cadastro() {
    const navigate = useNavigate();
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [senha2, setSenha2] = useState('');
    const [endereco, setEndereco] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [codCidade, setCodCidade] = useState('');
    const [uf, setUf] = useState('');
    const [cep, setCEP] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [loading, setLoading] = useState('');
    const [cidades, setCidades] = useState([]);
    const [numero, setNumero] = useState('');
    const [whatsup, setWhatsup] = useState('');
    const [whatsup2, setWhatsup2] = useState('');
    const [dlgmessage, setDLGMessage] = useState('');
    const [ppdlgmessage, setPPDLGMessage] = useState(false);

    function SalvarCidade(e) {
        const [cid, est] = e.target[e.target.selectedIndex].text.split(' - ');
        setCidade(cid);
        setUf(est);
        setCodCidade(e.target.value);
    }

    function ProcessaCadastro(e) {
        e.preventDefault();
        setMensagem('');

        if (senha !== senha2) {
            setMensagem('Senhas não conferem');
            return;
        }

        if (whatsup !== whatsup2) {
            setMensagem('Numero do WhatsUp não conferem');
            return;
        }

        setLoading(true);

        apibasic.post('v1/usuarios/registro', {
            nome,
            email,
            senha: senha.length > 0 ? SaltPassword(senha) : '',
            whatsup,
            endereco,
            numero,
            complemento,
            bairro,
            cidade,
            uf,
            cep,
            cod_cidade: codCidade
        })
            .then(response => {
                if (response.status === 201) {
                    localStorage.setItem('eamigaToken', response.data.token);
                    localStorage.setItem('eamigaId', response.data.id_usuario);
                    localStorage.setItem('eamigaEmail', email);
                    localStorage.setItem('eamigaCodCidade', codCidade);
                    localStorage.setItem('eamigaUF', uf);
                    localStorage.setItem('eamigaCidade', cidade);
                    setDLGMessage('Cadastro realizado com sucesso');
                    setPPDLGMessage(true);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setMensagem('Ocorreu um erro no cadastro ' + response.response.data.erro);
                }
            })
            .catch(err => {
                setLoading(false);
                setMensagem(err.response.data.erro);
            })
    }

    function confirmaCad() {
        setPPDLGMessage(false);
        navigate('/');
    }

    useEffect(() => {
        apibasic.get(config.CIDADES)
            .then(response => {
                setCidades(response.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    return <>
        <div className="row">
            <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
                <form className="form-cadastro mt-5">
                    <img className="mb-5" src={Logo} alt="" />
                    <h3 className="mb-4">Crie sua conta e tenha os beneficios</h3>
                    <h6 className="mb-3">Informe os dados abaixo</h6>
                    <div className="form-floating mb-3">
                        <input type="text" onChange={(e) => setNome(e.target.value)} className="form-control" id="floatinginput" placeholder="Nome completo" />
                        <label htmlFor="floatinginput">Nome completo</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="floatinginput" placeholder="e-mail" />
                        <label htmlFor="floatinginput">E-mail</label>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-floating mb-3">
                                <input type="passord" onChange={(e) => setSenha(e.target.value)} className="form-control" id="floatinginput" placeholder="Senha" />
                                <label htmlFor="floatinginput">Senha</label>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-floating mb-3">
                                <input type="passord" onChange={(e) => setSenha2(e.target.value)} className="form-control" id="floatinginput" placeholder="Confirme a Senha" />
                                <label htmlFor="floatinginput">Confirme a Senha</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-floating mb-3">
                                <input type="text" onChange={(e) => setWhatsup(e.target.value)} className="form-control" id="floatinginput" placeholder="Senha" />
                                <label htmlFor="floatinginput">Numero do WhatsUP</label>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-floating mb-3">
                                <input type="text" onChange={(e) => setWhatsup2(e.target.value)} className="form-control" id="floatinginput" placeholder="Confirme a Senha" />
                                <label htmlFor="floatinginput">Confirme o numero do WhatsUP</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="form-floating mb-3">
                                <input type="text" onChange={(e) => setEndereco(e.target.value)} className="form-control" id="floatinginput" placeholder="Endereço" />
                                <label htmlFor="floatinginput">Endereço</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-floating mb-3">
                                <input type="text" onChange={(e) => setNumero(e.target.value)} className="form-control" id="floatinginput" placeholder="Endereço" />
                                <label htmlFor="floatinginput">Numero</label>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-floating mb-3">
                                <input type="text" onChange={(e) => setComplemento(e.target.value)} className="form-control" id="floatinginput" placeholder="Complemento" />
                                <label htmlFor="floatinginput">Complemento</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-floating mb-3">
                                <input type="text" onChange={(e) => setBairro(e.target.value)} className="form-control" id="floatinginput" placeholder="Bairro" />
                                <label htmlFor="floatinginput">Bairro</label>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-control mb-2">
                                <select name="cidades" id="cidades" onChange={SalvarCidade} >
                                    <option value="000000">Cidade</option>
                                    {
                                        cidades.map(c => {
                                            return <option key={c.cod_cidade}
                                                value={c.cod_cidade}>
                                                {c.cidade} - {c.uf}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="form-floating">
                        <input type="text" onChange={(e) => setCEP(e.target.value)} className="form-control mb-2" id="floatinginput" placeholder="CEP" />
                        <label htmlFor="floatinginput">CEP</label>
                    </div>

                    <button type='button'
                        className="w-100 btn btn-lg btn-danger"
                        disabled={loading}
                        onClick={(e) => ProcessaCadastro(e)}>
                        {loading ? <div>
                            <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                            <span className="ms-2">Enviando...</span>
                        </div>
                            :
                            <span className="ms-2">Criar Conta</span>}
                    </button>

                    {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                    <LinkLogin />
                </form>
            </div>
            <div className="col-sm-6 px-0 d-none d-sm-block">
                <img className="background-cadastro" src={Fundo} alt="Opnet Mais" />
            </div>
        </div>
        <DLGMESSAGE
            trigger={ppdlgmessage}
            setTrigger={setPPDLGMessage}
            onConfirma={confirmaCad}
            title={'Cadastro de Usuario'}
            message={dlgmessage}
        />
    </>
}

export default Cadastro;