import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import NAVBAR from "../../../components/navbar";
import config from "../../../config";
import MESSAGE from "../../../components/message";
import api from "../../../services/api";
import DIALOGWAIT from "../../../dialog/dialogwait";
import RADIO from "../../../components/radio";

function AdminNotificacao() {
    const myRefreshToken = '';
    const [titulo, setTitulo] = useState('');
    const [corpo, setCorpo] = useState('');
    const [imagem, setImagem] = useState('');
    const [mensagem, setMensagem] = useState(''); 
    const [loading, setLoading] = useState(false);
    const [tipoenvio, setTipoEnvio] = useState('N');

    
  const navigate = useNavigate();

    async function getAccessToken(refreshToken) {
        const response = await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'client_id': '112252447206247972368',
                'client_secret': '993461e03fb9514535c5a7489c28663911dbadad',
                'refresh_token': refreshToken,
                'grant_type': 'refresh_token'
            })
        });

        const data = await response.json();
        return data.access_token;
    }
    // function getAccessToken() {
    //     return new Promise(function(resolve, reject) {
    //       const key = require('../../../fcm/serviceAcountKey.json');
    //       const jwtClient = new google.auth.JWT(
    //         key.client_email,
    //         null,
    //         key.private_key,
    //         SCOPES,
    //         null
    //       );
    //       jwtClient.authorize(function(err, tokens) {
    //         if (err) {
    //           reject(err);
    //           return;
    //         }
    //         resolve(tokens.access_token);
    //       });
    //     });
    //   }

    async function enviaAlerta() {

        const accessToken = // 'ya29.a0AcM612yIhu9A8XXvxs7-sxf0nkQks84JceYSfVpUqjonHCWiKMjpw2Pu5xgpMUBWI8977-vx9faiqNMI8Okyz0cDOrlQ9SW4h_IjGyk_BNEbmpfjfSZry3refR4p4NIwl9Nmz-3EGXc8bhQopozR4_geutiHD9LGJgxDNLyOaCgYKAdcSARESFQHGX2MiXQoFfTWbH0_r_LIO3D1npw0175';
        await getAccessToken(myRefreshToken);

        const dados = {
            "message": {
                "token": "cjPp4xqj0Nq7QauYxGcow5:APA91bEbMr4wR1PglNsONZ0Q0G4-LAYvuAHK1kVaeCluO6U9G08iEBIYi93xT7g3Yxdg-tL0WZNqdVEUaLPIYpUrugdZW673zdTxbTE0vNBU3KP0ErCIkFM_Odkyg_fZoNXgVQNxjY5Q",
                "notification": {
                    "title": "FCM Message",
                    "body": "This is an FCM notification message!"
                }
            }
        };

        const response = await fetch('https://fcm.googleapis.com/v1/projects/actus-f4d0e/messages:send', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dados)
        });

        const result = await response.json();
        console.log(result);
    }

    function enviaMensagem(){
        const dados = 
            {
                "type": "M",
                "title": titulo,
                "body": corpo,
                "image": imagem
            } 
        setLoading(true);
        api.post(`${config.NOTIFICACAO}?p=${tipoenvio}`, dados)
        .then(response => {
            if (response.status === 202) {
              setLoading(false);
              navigate('/');
            } else {
              setLoading(false);
              setMensagem(response.response.data.erro)
            }
          })
    }  

    return <div>
        <NAVBAR />
        <div className='mt-page'>
            <MESSAGE msg={mensagem} />
            <form>
                <div className="row">
                    <div className="col-md-10">
                        <label htmlFor="forTitulo" className="form-label">Titulo</label>
                        <input type="text"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                            className="form-control" id="forTitulo" />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forCorpo" className="form-label">Mensagem</label>
                        <textarea type="text"
                            value={corpo}
                            onChange={(e) => setCorpo(e.target.value)}
                            className="form-control" id="forCorpo" />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forImagem" className="form-label">Link Imagem</label>
                        <input type="text"
                            value={imagem}
                            onChange={(e) => setImagem(e.target.value)}
                            className="form-control" id="forImagem" />
                    </div>
                    <div className="col-md-10">
                        <label htmlFor="forTEnvio" className="form-label">Tipo envio</label>
                        <RADIO                            
                            chave={'forTEnvio'}
                            avalores={['N', 'P']}
                            alabel={['Todos', 'Parceiros']}
                            setValor={setTipoEnvio}
                            valor={tipoenvio}
                        />
                    </div>
                    <div className="col-md-12 mt-5">
                      <button type="button" className="btn btn-info btn-large" onClick={(e) => enviaMensagem()}>Notificacao </button>
                    </div>
                </div>
            </form>
          
            {//<button type="button" onClick={(e) => enviaAlerta()}>Alerta</button>
            }
        </div>
        <DIALOGWAIT
              trigger={loading}              
              setTrigger={setLoading}           
           />
    </div>
}

export default AdminNotificacao;