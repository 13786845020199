import { useEffect, useState } from "react";
import NAVBAR from "../../../components/navbar";
import api from "../../../services/api";
import AdminContempladoModal from './modal';
import './style.css';
import config from '../../../config';
import AdminSORTEIO from "./sorteio";
import { formatDate } from "../../../components/formatos";

function AdminContemplado(){
    const [lcontemplado, setLContemplado] = useState([]);

    // Modal
    const [operacao, setOperacao] = useState('')
    const [dados, setDados] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSorteio, setIsOpenSorteio] = useState(false);

    function closeAdminContempladoModal(){
        setIsOpen(false);
    }
    function closeSorteio(){
        setIsOpenSorteio(false);
    }

    useEffect(()=>{
        api.get(config.ADM_CONTEMPLADOS)
        .then( response => {
            setLContemplado(response.data);
        });
    },[isOpen,isOpenSorteio])

    function novaContemplado(){
        setDados({           
           'id_sorteado':0,
           });
        setIsOpen(true);
    }
    function novoSorteio(){
        setIsOpenSorteio(true);
    }

    function editarContemplado(linha){
            api.get(`${config.ADM_CONTEMPLADOS}/${linha.id_sorteado}`)
            .then(response => {
                 setOperacao('UPDATE')
                 setDados(response.data[0]);
                 setIsOpen(true);
            }).catch(err => {
                console.log(err)
                //document.location = `/empresaamiga/erro?m=No momento não conseguimos alcançar o servidor`
        })  
        }


    return <div>
        <NAVBAR />
        <AdminContempladoModal isOpen={isOpen}
                  onRequestClose={closeAdminContempladoModal}                 
                  operacao={operacao}
                  dados={dados}
        /> 
        <AdminSORTEIO isOpen={isOpenSorteio}
                 onRequestClose={closeSorteio}
                 operacao={'SORTEIO'}
                 />   
        <div className="mt-page">
            <h1>Contemplados</h1>
            <table className="table table-striped table-bordered">
            <thead>
                <tr>
                <th scope="col"># <button type="button"
                onClick={(e)=>novaContemplado()}
                >Nova</button>
                <button type="button"
                onClick={(e)=>novoSorteio()}
                >Sorteio</button></th>
                <th scope="col" className="d-none d-md-table-cell hidden-sm">Campanha</th> 
                <th scope="col">Contemplado</th>                        
                <th scope="col">operação</th>
                </tr>
            </thead>        
            <tbody>
                {
                lcontemplado && lcontemplado.map((e,index) =>{
                        return <tr key={`cntp${index}`}>
                            <th scope="row">{formatDate(e.data_sorteio)}</th>
                            <td className="d-none d-md-table-cell hidden-sm">{e.den_campanha}</td>
                            <td>{e.nome_sorteado}</td>
                            <td><button className='btn btn-warnning' 
                                type="button"
                                onClick={()=>editarContemplado(e)}>Editar</button></td>
                        </tr>           
                    })   
                }            
            </tbody>
            </table>
        </div>     
    </div>
}

export default AdminContemplado;