import QRCode from "react-qr-code";
import POPUP from "../../../../components/popup";

function QRCODEWRITE(props) {
    function CloseConfirmacao() {
        props.setTrigger(false);
    }
    var Buffer = require('buffer/').Buffer
    const encodedString = Buffer.from(JSON.stringify(props.json)).toString('base64');
    return <POPUP
        trigger={props.trigger}
        setTrigger={CloseConfirmacao}>
        <div className="form-control">
            <div className="row">
                <h3>Scanei esse codigo pelo app</h3>
                <hr />
                <QRCode
                    value={encodedString}
                />
                {//<span>{JSON.stringify(props.json)}</span>
                }
                <div className="modal-footer mt-3">
                    <div className="col-3">
                        <div className="btn btn-danger" onClick={() => props.setTrigger(false)}>OK</div>
                    </div>                    
                </div>
            </div>
        </div>
    </POPUP>
}

export default QRCODEWRITE;