import  { useState, useEffect } from 'react';
import config from '../../config';
import './style.css';

export const ImageComponent = ({ base64img: base64, 
                                 titulo:titulo='Imagem', 
                                 class:classe='imagecompoent',
                                 base64: img64=true,
                                 width: width='100%'
                                 }) => {
    const [cacheBuster, setCacheBuster] = useState(Date.now());
    useEffect(() => {
      setCacheBuster(Date.now());
    }, [base64]);   
    
    if (!base64) {
      return null;
    }  
    if(img64){        
      if(base64.substr(0,4)==='data'){
        var imageUrl = base64;
      } else {
        var imageUrl = `data:image/png;base64,${base64}`;      
      }
    } else {
      imageUrl = `${config.BASEIMG}${base64}?t=${cacheBuster}`;;
    }

    return (
      <div className={`container ${classe}`}>
        <h2>{titulo}</h2>
        <img  src={imageUrl} alt="Imagem" width={width} />
      </div>
    );
}; 
