import axios from 'axios';
import config from '../config';

const apiimg = axios.create({
      baseURL: config.BASEIMG,
});

apiimg.interceptors.request.use(req => {
    req.headers = {
        ...req.headers,  // Mantém os cabeçalhos existentes
        'Content-Type': 'application/json'
    };
    return req;
},
(err)=>{
    console.log(err);
});

apiimg.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response.status === 401){
       localStorage.removeItem('eamigaconfig');
       document.location = `${config.BASENAME}/login`;
    }
    return error;
});

export default apiimg;