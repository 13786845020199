const config = {
   // BASENAME: '/actus',
   // BASEIMG:'http://192.168.10.1/actus/images',
   // BASEAPI:'http://192.168.10.10:9550',
   // BASEAPI:'http://192.168.5.138:9550',
   BASENAME: '/',
   BASEAPI:'https://horse.opnet.com.br/actus',
   BASEIMG:'https://actus.opnet.com.br/images',
   BASICAPI: '776FDED0-8900-4554-AB97-C8BFB301D298',   
   ADM_AVISOS: '/v1/adm/avisos',
   ADM_CAMPANHA: '/v1/adm/campanhas',
   ADM_ESTABELECIMENTOS: '/v1/adm/estabelecimentos',
   ADM_CIDADES: '/v1/adm/cidades', 
   ADM_CATEGORIAS: '/v1/adm/categorias',
   ADM_DASHBOARD: '/v1/adm/dashboard',
   ADM_CONTEMPLADOS: '/v1/adm/contemplados',
   ADM_SORTEIO: '/v1/adm/sorteio',
   USUARIOS: '/v1/usuarios',
   CIDADES: 'v1/cidades',
   CAMPANHAS: '/v1/campanhas',
   ESTABELECIMENTOS: '/v1/estabelecimentos',
   CUPONS: '/v1/usuarios/cupons',
   EXTRATO: '/v1/extrato',
   CONTEMPLADOS: '/v1/campanhas/contemplados',
   PARCEIRO_VENDAS: '/v1/parceiro/vendas',
   PARCEIRO_USUARIOS: '/v1/parceiro/usuarios',
   NOTIFICACAO: '/v1/usuarios/notificacao',   
   AVISOS: '/v1/avisos',   
}

export default config;