import config from '../../config';

function IMAGEM(props){
    if(!props.participantes){       
        var BASEIMAGEM=`${config.BASEIMG}${props.src}`;
    } else {
        var BASEIMAGEM=props.src; 
    }
    return <img src={BASEIMAGEM} className="rounded mx-auto d-block" width={props.width} />
}

export default IMAGEM;