import { useEffect } from 'react';
import { useState } from 'react';
import Navbar from '../../components/navbar';
import api from '../../services/api';
import AlterarSenhaModal from "../../components/perfil/modal";
// import { useSearchParams } from 'react-router-dom';
import { verificaCelular } from '../../functions/validatelefone';
import { foneMask } from '../../functions/foneMask.js';
import CONFIRMACAO from '../../components/confirmacao';
import config from "../../config";

function Perfil(){

    const [nome, setNome] = useState('');
    const [email,setEmail] = useState('');
    const [whatsup,setWhatsup] = useState('');    
    const [msg, setMsg] = useState('');
    const [msgErro, setMsgErro] = useState('');
    const [isAlterarSenhaOpen, setIsAlterarSenhaOpen] = useState(false);
    const [confirmaremocao, setConfirmaRemocao] = useState(false);

    function ExibeMensagem(m){
        setMsg(m);
        setTimeout(()=>setMsg(''),3000);        
    }

    function ExibeErro(msgErro){
        setMsgErro(msgErro);
        setTimeout(()=>setMsgErro(''),3000);
    }    

    function SalvarDados(){
        setMsg('');
        setMsgErro('');
        if(whatsup.length>0){
            if (!verificaCelular(whatsup)) {
               alert('numero do WhatApp invalido!')
               return false;
            }    
        }
        api.patch('/v1/usuarios',{
            nome,
            email,
            whatsup
        })
        .then(response => {
            if(response.status===200){
                ExibeMensagem('Perfil salvo com sucesso');
            }else{
                ExibeMensagem(response.data.erro); 
            }
        })
        .catch(err => {
            ExibeMensagem(err.response.data.erro); 
        });
    }

    function SolicitarRemocao(){       
        api.post('/v1/usuarios/solicitaremocao',{           
            email
        }).then(response => {
            if(response.status===201){
               ExibeMensagem('Solicitação de remoção do seu cadastro foi enviado para seu e-mail'); 
            } else {
               ExibeErro('não foi possível realizar sua solicitação')
            }
        });       
    }
 
    function closeModalAlterarSenha(){
        setIsAlterarSenhaOpen(false);
      }

    function openModalAlterarSenha(eemail){
        if(eemail!==''){
            setIsAlterarSenhaOpen(true);
        }
      }

    useEffect(() =>{
        api.get(`${config.USUARIOS}/${localStorage.getItem('eamigaId')}`)
        .then(response => {
             setNome(response.data[0].nome);
             setEmail(response.data[0].email);             
             setWhatsup(foneMask(response.data[0].whatsup,true));            
        })
        .catch(err => {
            console.log(err);
        });
     },[]);           

    return <>
        <Navbar />
        <AlterarSenhaModal isOpen={isAlterarSenhaOpen}
                           onRequestCloseSenha={closeModalAlterarSenha}
                           email={email}                    
       /> 
       <CONFIRMACAO
                    trigger={confirmaremocao}
                    setTrigger={setConfirmaRemocao}
                    title={"Confirma solicitação de Exclusão"}
                    message={'Voce está solicitando a remoção permanente do seu cadastro, '+
                             'não ha maneiras de recupera-lo, mas voce pode recriar um novo cadastro.'+
                             'Voce receberá um e-mail para efetivar sua solicitação. '}
                    onConfirma={SolicitarRemocao}
       />
        <div className="conteiner-fluid mt-page">
            <div className="row col-lg-6 offset-lg-3">
                <div className="col-12 mt-4 d-flex justify-content-between">
                    <h3>Meus Perfil</h3>
                    <button onClick={(e) => openModalAlterarSenha(email)} className="btn btn-danger">Alterar Senha</button>
                </div>            
                <div className="row col-lg-12">
                    <div className="row m-2">
                        <form>
                            <div className="mb-3">
                                <label htmlFor="InputName" className="form-label">Nome</label>
                                <input type="text" onChange={(e)=> setNome(e.target.value)} value={nome} 
                                className="form-control" id="inputName" aria-describedby="nome" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="InputEmail" className="form-label">E-Mail</label>
                                <input type="email" disabled onChange={(e)=> setEmail(e.target.value)} value={email} 
                                className="form-control" id="inputEmail" aria-describedby="email" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="InputWhatsUP" className="form-label">SMS/WhatsApp</label>
                                <input type="text" 
                                       onChange={(e)=> setWhatsup(foneMask(e.target.value,true))} 
                                       value={whatsup} 
                                       className="form-control" 
                                       id="InputWhatsUP" 
                                       aria-describedby="whatsup" />
                            </div>                        
                            <div className="d-flex justify-content-end">
                                <button type="button" 
                                        onClick={SalvarDados} 
                                        className="btn btn-lg btn-danger">Salvar Dados
                                </button>
                            </div>
                            <div className="d-flex justify-content-begin">
                                <button type="button" 
                                        onClick={()=>setConfirmaRemocao(true)} 
                                        className="btn btn-remocao"
                                        >** Solicitar remoção do meu usuário
                                </button>
                            </div>
                            {
                            msg.length>0 ? <div className="alter alert-sucess mt-4 text-center">{msg}</div>: null
                            }
                            {
                            msgErro.length>0 ? <div className="alter alert-danger mt-4 text-center">{msgErro}</div>: null
                            }                        
                        </form>
                    </div>                    
                </div>
            </div>
        </div>
    </>
}
export default Perfil;