import { useEffect, useState } from "react";
import NAVBAR from "../../../components/navbar";
import api from "../../../services/api";
import AdminAvisoModal from './modal';
import './style.css';
import { formatDate, getHoje } from "../../../components/formatos";
import config from '../../../config';
import FOOTER from "../../../components/footer";

function AdminAviso(){
    const [lavisos, setLAvisos] = useState([]);

    // Modal
    const [operacao, setOperacao] = useState('')
    const [dados, setDados] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    function closeAdminAvisoModal(){
        setIsOpen(false);
    }

    useEffect(()=>{
        api.get(config.ADM_AVISOS)
        .then( response => {
            setLAvisos(response.data);
        });
    },[isOpen])

    useEffect(()=>{
        api.get(config.ADM_AVISOS)
        .then( response => {
            setLAvisos(response.data);
        });
    },[])

    function novoAviso(){
        setDados(           
            {
                "id_aviso": 0,
                "title": "",
                "body": "",
                "image": "",
                "validade": getHoje()
            } 
        );
        setIsOpen(true);
    }

    function editarAviso(linha){
        setOperacao('UPDATE')
        setDados(linha);
        setIsOpen(true);
    }

    return <div>
        <NAVBAR />
        <AdminAvisoModal isOpen={isOpen}
                  onRequestClose={closeAdminAvisoModal}                 
                  operacao={operacao}
                  dados={dados}
        /> 
        <div className="mt-page">
            <h1>Avisos</h1>
            <div className="table-container">
                <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                    <th scope="col"># <button type="button"
                    onClick={(e)=>novoAviso()}
                    >Nova</button></th>
                    <th scope="col" className="d-none d-md-table-cell hidden-sm">Titulo</th> 
                    <th scope="col">Validade</th>                                
                    <th scope="col">operação</th>
                    </tr>
                </thead>        
                <tbody>
                    {
                        lavisos.map((e,index) =>{
                            return <tr key={`vnd${index}`}>
                                <th scope="row">{e.id_aviso}</th>
                                <td className="d-none d-md-table-cell hidden-sm">{e.title}</td>
                                <td>{formatDate(e.validade)}</td>                                
                                <td><button className='btn btn-warnning' 
                                    type="button"
                                    onClick={()=>editarAviso(e)}>Editar</button></td>
                            </tr>           
                        })   
                    }            
                </tbody>
                </table>
            </div>
        </div>
        <FOOTER />
    </div>
}

export default AdminAviso;